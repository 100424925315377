import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import "./setUpNewJobDialog.scss";
import api from "../../apiInterceptor";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Snackbar,
} from "@mui/material";
import {
  buttonStyles,
  textFieldStyles,
  checkboxStyles,
  datePickerStyles,
  menuItemStyles,
  selectStyles,
  labelStyles,
  formControlStyles,
} from "./setUpNewJobDialogStyle";
import { showSuccessAlert } from "utils/utils";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isEmpty } from "lodash";
const create_cronjobAPITestData = require("../../assets/apiTestData/create_cronjob-test-data.json");
const getjobAPITestData = require("../../assets/apiTestData/getjob-test-data.json");
const modifyjobAPITestData = require("../../assets/apiTestData/modifyjob-test-data.json");

const SetUpNewJobDialog = ({
  jobDetails = null,
  isEditMode = false,
  projectKey,
  projVersion,
  projFg,
  getJobDetails,
  setEditMode,
}) => {
  const COMMON_API_URL = localStorage.getItem("COMMON_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [periodicity, setPeriodicity] = useState("");
  const [jobType, setJobType] = useState("BATCH_EXECUTION");
  const [unit, setUnit] = useState(1);
  const [unitDisabled, setUnitDisabled] = useState(false);
  const [startHour, setStartHour] = useState(1);
  const [endHour, setEndHour] = useState(2);
  const [jobRunTime, setJobRunTime] = useState(1);
  const [preferredMinute, setPreferredMinute] = useState(0);
  const [preferredDate, setPreferredDate] = useState(1);
  const defaultSelectedPreferredDaysList = [
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
    "SUN",
  ];
  const [preferredDays, setPreferredDays] = useState(
    defaultSelectedPreferredDaysList
  );
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const {
    updateIsLoading,
    updateSetUpNewJobDialogStatus,
    updateAlertMessage,
    updateSnackbarMsg,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  const isSetUpNewJobDialogOpen = useSelector(
    (state) => state.isSetUpNewJobDialogOpen
  );
  const snackbarMsg = useSelector((state) => state.snackbarMsg);

  const jobTypeOptions = [
    {
      key: "BATCH_EXECUTION",
      name: "BATCH_EXECUTION",
    },
    {
      key: "STREAM_EXECUTION",
      name: "STREAM_EXECUTION",
    },
  ];
  const periodicityOptions = [
    {
      key: "minutes",
      name: "Minutes",
    },
    {
      key: "hourly",
      name: "Hourly",
    },
    {
      key: "daily",
      name: "Daily",
    },
    {
      key: "weekly",
      name: "Weekly",
    },
    {
      key: "monthly",
      name: "Monthly",
    },
  ];
  const preferredDaysList = [
    { key: "MON", name: "Monday" },
    { key: "TUE", name: "Tuesday" },
    { key: "WED", name: "Wednesday" },
    { key: "THU", name: "Thursday" },
    { key: "FRI", name: "Friday" },
    { key: "SAT", name: "Saturday" },
    { key: "SUN", name: "Sunday" },
  ];
  const hoursList = [
    { key: 0, name: "00:00" },
    { key: 1, name: "01:00" },
    { key: 2, name: "02:00" },
    { key: 3, name: "03:00" },
    { key: 4, name: "04:00" },
    { key: 5, name: "05:00" },
    { key: 6, name: "06:00" },
    { key: 7, name: "07:00" },
    { key: 8, name: "08:00" },
    { key: 9, name: "09:00" },
    { key: 10, name: "10:00" },
    { key: 11, name: "11:00" },
    { key: 12, name: "12:00" },
    { key: 13, name: "13:00" },
    { key: 14, name: "14:00" },
    { key: 15, name: "15:00" },
    { key: 16, name: "16:00" },
    { key: 17, name: "17:00" },
    { key: 18, name: "18:00" },
    { key: 19, name: "19:00" },
    { key: 20, name: "20:00" },
    { key: 21, name: "21:00" },
    { key: 22, name: "22:00" },
    { key: 23, name: "23:00" },
    { key: 24, name: "24:00" },
  ];

  const minutesList = [
    { key: 0, name: "00" },
    { key: 1, name: "01" },
    { key: 2, name: "02" },
    { key: 3, name: "03" },
    { key: 4, name: "04" },
    { key: 5, name: "05" },
    { key: 6, name: "06" },
    { key: 7, name: "07" },
    { key: 8, name: "08" },
    { key: 9, name: "09" },
    { key: 10, name: "10" },
    { key: 11, name: "11" },
    { key: 12, name: "12" },
    { key: 13, name: "13" },
    { key: 14, name: "14" },
    { key: 15, name: "15" },
    { key: 16, name: "16" },
    { key: 17, name: "17" },
    { key: 18, name: "18" },
    { key: 19, name: "19" },
    { key: 20, name: "20" },
    { key: 21, name: "21" },
    { key: 22, name: "22" },
    { key: 23, name: "23" },
    { key: 24, name: "24" },
    { key: 25, name: "25" },
    { key: 26, name: "26" },
    { key: 27, name: "27" },
    { key: 28, name: "28" },
    { key: 29, name: "29" },
    { key: 30, name: "30" },
    { key: 31, name: "31" },
    { key: 32, name: "32" },
    { key: 33, name: "33" },
    { key: 34, name: "34" },
    { key: 35, name: "35" },
    { key: 36, name: "36" },
    { key: 37, name: "37" },
    { key: 38, name: "38" },
    { key: 39, name: "39" },
    { key: 40, name: "40" },
    { key: 41, name: "41" },
    { key: 42, name: "42" },
    { key: 43, name: "43" },
    { key: 44, name: "44" },
    { key: 45, name: "45" },
    { key: 46, name: "46" },
    { key: 47, name: "47" },
    { key: 48, name: "48" },
    { key: 49, name: "49" },
    { key: 50, name: "50" },
    { key: 51, name: "51" },
    { key: 52, name: "52" },
    { key: 53, name: "53" },
    { key: 54, name: "54" },
    { key: 55, name: "55" },
    { key: 56, name: "56" },
    { key: 57, name: "57" },
    { key: 58, name: "58" },
    { key: 59, name: "59" },
  ];

  const daysList = [
    { key: 1, name: "01" },
    { key: 2, name: "02" },
    { key: 3, name: "03" },
    { key: 4, name: "04" },
    { key: 5, name: "05" },
    { key: 6, name: "06" },
    { key: 7, name: "07" },
    { key: 8, name: "08" },
    { key: 9, name: "09" },
    { key: 10, name: "10" },
    { key: 11, name: "11" },
    { key: 12, name: "12" },
    { key: 13, name: "13" },
    { key: 14, name: "14" },
    { key: 15, name: "15" },
    { key: 16, name: "16" },
    { key: 17, name: "17" },
    { key: 18, name: "18" },
    { key: 19, name: "19" },
    { key: 20, name: "20" },
    { key: 21, name: "21" },
    { key: 22, name: "22" },
    { key: 23, name: "23" },
    { key: 24, name: "24" },
    { key: 25, name: "25" },
    { key: 26, name: "26" },
    { key: 27, name: "27" },
    { key: 28, name: "28" },
  ];

  useEffect(() => {
    if (isEditMode && jobDetails) {
      fillJobDetails(jobDetails);
    } else {
      const todayDate = new Date();
      setStartDate(getFormattedDate(todayDate));
    }
    return () => {};
  }, []);

  const fillJobDetails = (job) => {
    if (job.start_date && job.start_date !== "None") {
      setStartDate(job.start_date);
    } else {
      setStartDate("");
    }
    if (job.end_date && job.end_date !== "None") {
      setEndDate(job.end_date);
    } else {
      setEndDate("");
    }
    if (job.job_type) {
      setJobType(job.job_type);
    }
    setPeriodicity(job.ndays);
    setUnit(job.unit || 1);
    setStartHour(job.start_hour || 1);
    setEndHour(job.end_hour || 2);
    setJobRunTime(job.job_run_time || 1);
    setPreferredDays(job.preferred_days || defaultSelectedPreferredDaysList);
    setDescription(job.job_desc);
    setPreferredMinute(job.preferred_minute || 0);
    setPreferredDate(job.preferred_date || 1);
  };

  const convertISOToDate = (isoDate) => {
    try {
      let [datePart] = isoDate.split(" ");
      let [year, month, day] = datePart.split("-");
      if (day && year && month) return `${day}/${month}/${year}`;
      else return "";
    } catch (error) {
      return "";
    }
  };

  const formatDateString = (dateString) => {
    if (dateString.includes("-")) {
      return convertISOToDate(dateString);
    }
    return swapDayMonth(dateString);
  };

  const getFormattedDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return "";
    }
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    const formattedDate = mm + "/" + dd + "/" + yyyy;
    return formattedDate;
  };

  const handleClose = () => {
    updateSetUpNewJobDialogStatus(false);
    setEditMode(false);
  };

  const handleSubmit = async (e) => {
    if (
      (periodicity === "minutes" || periodicity === "hourly") &&
      startHour >= endHour
    ) {
      updateSnackbarMsg("Start hour should be earlier than the end hour.");
      return;
    } else {
      updateSnackbarMsg(null);
    }
    await configureJob();
  };

  const configureJob = async () => {
    const apiUrl =
      COMMON_API_URL + `${isEditMode ? "modifyjob" : "create_cronjob"}`;
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = getCreteJobPayload();
    if (isEditMode) {
      payload["job_id"] = jobDetails.job_id;
    }
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = isEditMode
          ? modifyjobAPITestData
          : create_cronjobAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const message = response.data.message;
        showSuccessAlert(message, updateSuccessAlertMessage);
        handleClose();
        getJobDetails();
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const getCreteJobPayload = () => {
    const payload = {
      projectkey: projectKey,
      projectVersion: projVersion,
      projFg: projFg,
      job_type: jobType,
      conn_type: "periodic",
      start_date: formatDateString(startDate),
      end_date: formatDateString(endDate),
      periodicity: periodicity,
      job_desc: description,
      product_type: "oda",
      unit: unit,
      start_hour: startHour,
      end_hour: endHour,
      job_run_time: jobRunTime,
      preferred_days: preferredDays,
      preferred_minute: preferredMinute,
      preferred_date: preferredDate,
    };
    return payload;
  };

  const handleChange = (e) => {
    try {
      const value = e.target.value;
      const type = e.target.type;
      const name = e.target.name;
      if (type === "text" && name === "description") {
        if (!/^[a-zA-Z\s]*$/.test(value)) {
          return;
        }
      }

      switch (name) {
        case "periodicity":
          setPeriodicity(value);
          if (value === "daily" || value === "weekly") {
            setUnitDisabled(true);
            setUnit(1);
          } else {
            setUnitDisabled(false);
          }
          if (value === "weekly") {
            setPreferredDays([]);
          }
          break;
        case "unit":
          const unitValue = Number(value);
          if (unitValue >= 1) {
            setUnit(unitValue);
          }
          break;
        case "startHour":
          const startHourValue = Number(value);
          if (startHourValue >= 0) {
            setStartHour(startHourValue);
          }
          break;
        case "endHour":
          const endHourValue = Number(value);
          if (endHourValue >= 0) {
            setEndHour(endHourValue);
          }
          break;
        case "jobRunTime":
          const jobRunTimeValue = Number(value);
          if (jobRunTimeValue >= 0) {
            setJobRunTime(jobRunTimeValue);
          }
          break;
        case "jobType":
          setJobType(value);
          break;
        case "description":
          setDescription(value);
          break;
        case "preferredMinute":
          setPreferredMinute(value);
          break;
        case "preferredDate":
          setPreferredDate(value);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (day) => {
    const dayPresent = preferredDays.includes(day);
    if (!dayPresent && periodicity === "weekly" && preferredDays.length === 1) {
      return;
    }
    if (dayPresent) {
      setPreferredDays(preferredDays.filter((item) => item !== day));
    } else {
      setPreferredDays([...preferredDays, day]);
    }
  };

  const swapDayMonth = (dateString) => {
    const match = dateString.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
    if (!match) {
      return dateString;
    }
    const day = match[1];
    const month = match[2];
    const year = match[3];
    return `${month}/${day}/${year}`;
  };

  const handleDateChange = (newValue = null, dateType) => {
    let value = "";
    if (newValue) {
      value = getFormattedDate(newValue.$d);
    }
    if (dateType === "startDate") {
      setStartDate(value);
    } else if (dateType === "endDate") {
      setEndDate(value);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isSetUpNewJobDialogOpen}
        aria-labelledby="Set Up New Job"
        className="setup-new-job-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="setup-new-job-title">
          <Box>{`${isEditMode ? "Update" : "Set Up New"}` + " Job"}</Box>
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems="stretch">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="start-date-input"
                label="Start Date"
                value={dayjs(startDate)}
                onChange={(newValue) => handleDateChange(newValue, "startDate")}
                sx={textFieldStyles}
                maxDate={dayjs(endDate)}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                  layout: {
                    sx: datePickerStyles,
                  },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={dayjs(endDate)}
                onChange={(newValue) => handleDateChange(newValue, "endDate")}
                sx={textFieldStyles}
                minDate={dayjs(startDate)}
                slotProps={{
                  actionBar: {
                    actions: ["clear"],
                  },
                  layout: {
                    sx: datePickerStyles,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl sx={formControlStyles} size="small">
              <InputLabel sx={labelStyles}>Job Type</InputLabel>
              <Select
                name="jobType"
                size="small"
                placeholder="Job Type"
                label="Job Type"
                value={jobType}
                onChange={handleChange}
                variant="outlined"
                sx={selectStyles}
                MenuProps={{ sx: selectStyles }}
              >
                {jobTypeOptions.map((option, index) => (
                  <MenuItem
                    sx={menuItemStyles}
                    key={index + "-jobType"}
                    value={option.key}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack
              direction="row"
              gap="10px"
              alignItems="center"
              className="select-container"
            >
              <FormControl sx={formControlStyles} className="w-50" size="small">
                <InputLabel sx={labelStyles}>Periodicity</InputLabel>
                <Select
                  name="periodicity"
                  size="small"
                  placeholder="Periodicity"
                  label="Periodicity"
                  value={periodicity}
                  onChange={handleChange}
                  variant="outlined"
                  sx={selectStyles}
                  MenuProps={{ sx: selectStyles }}
                >
                  {periodicityOptions.map((option, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index + "-periodicity"}
                      value={option.key}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className="w-50"
                type="number"
                name="unit"
                size="small"
                label="Unit"
                pattern="[0-9]*"
                min="1"
                value={unit}
                disabled={unitDisabled}
                onChange={handleChange}
                variant="outlined"
                sx={textFieldStyles}
                autoComplete="off"
                required
              />
            </Stack>
            {periodicity === "minutes" || periodicity === "hourly" ? (
              <>
                <Stack
                  direction="row"
                  gap="10px"
                  alignItems="center"
                  className="select-container"
                >
                  <FormControl
                    sx={formControlStyles}
                    className="w-50"
                    size="small"
                  >
                    <InputLabel sx={labelStyles}>Start Hour</InputLabel>
                    <Select
                      name="startHour"
                      size="small"
                      placeholder="Start Hour"
                      label="Start Hour"
                      value={startHour}
                      onChange={handleChange}
                      variant="outlined"
                      sx={selectStyles}
                      MenuProps={{ sx: selectStyles }}
                    >
                      {hoursList.map((option, index) => (
                        <MenuItem
                          sx={menuItemStyles}
                          key={index + "-startHour"}
                          value={option.key}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={formControlStyles}
                    className="w-50"
                    size="small"
                  >
                    <InputLabel sx={labelStyles}>End Hour</InputLabel>
                    <Select
                      name="endHour"
                      size="small"
                      placeholder="End Hour"
                      label="End Hour"
                      value={endHour}
                      onChange={handleChange}
                      variant="outlined"
                      sx={selectStyles}
                      MenuProps={{ sx: selectStyles }}
                    >
                      {hoursList.map((option, index) => (
                        <MenuItem
                          sx={menuItemStyles}
                          key={index + "-endHour"}
                          value={option.key}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </>
            ) : (
              <></>
            )}
            {periodicity === "daily" ||
            periodicity === "weekly" ||
            periodicity === "monthly" ? (
              <FormControl sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>Job Run Time</InputLabel>
                <Select
                  name="jobRunTime"
                  size="small"
                  placeholder="Job Run Time"
                  label="Job Run Time"
                  value={jobRunTime}
                  onChange={handleChange}
                  variant="outlined"
                  sx={selectStyles}
                  MenuProps={{ sx: selectStyles }}
                >
                  {hoursList.map((option, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index + "-jobRunTime"}
                      value={option.key}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
            {periodicity !== "monthly" ? (
              <Stack>
                <Box className="checkbox-lable">Preferred Days</Box>
                <Stack
                  className="preferred-days-container"
                  direction="row"
                  alignItems="center"
                >
                  {preferredDaysList.map((day) => (
                    <FormControlLabel
                      key={day.key}
                      className="preferred-days-checkbox"
                      control={
                        <Checkbox
                          sx={checkboxStyles}
                          checked={preferredDays.includes(day.key)}
                          onChange={() => handleCheckboxChange(day.key)}
                        />
                      }
                      label={day.name}
                    />
                  ))}
                </Stack>
              </Stack>
            ) : (
              <FormControl sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>Preferred Date</InputLabel>
                <Select
                  name="preferredDate"
                  size="small"
                  placeholder="Preferred Date"
                  label="Preferred Date"
                  value={preferredDate}
                  onChange={handleChange}
                  variant="outlined"
                  sx={selectStyles}
                  MenuProps={{ sx: selectStyles }}
                >
                  {daysList.map((option, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index + "-preferredDate"}
                      value={option.key}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {periodicity !== "minutes" && (
              <FormControl sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>Preferred Minute</InputLabel>
                <Select
                  name="preferredMinute"
                  size="small"
                  placeholder="Preferred Minute"
                  label="Preferred Minute"
                  value={preferredMinute}
                  onChange={handleChange}
                  variant="outlined"
                  sx={selectStyles}
                  MenuProps={{ sx: selectStyles }}
                >
                  {minutesList.map((option, index) => (
                    <MenuItem
                      sx={menuItemStyles}
                      key={index + "-preferredMinute"}
                      value={option.key}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              type="text"
              name="description"
              size="small"
              label="Description"
              value={description}
              onChange={handleChange}
              variant="outlined"
              sx={textFieldStyles}
              autoComplete="off"
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            gap="10px"
            paddingBottom="20px"
            paddingRight="15px"
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              autoFocus
              sx={buttonStyles}
              disabled={
                !startDate ||
                !endDate ||
                !periodicity ||
                (periodicity !== "monthly" && preferredDays.length === 0) ||
                !jobType ||
                !description
              }
            >
              {isEditMode ? "Update" : "Configure"}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleClose()}
              autoFocus
              sx={buttonStyles}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default SetUpNewJobDialog;
