import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as ViewRunningJobIcon } from "../../../assets/icons/tableIcon.svg";
import { ReactComponent as RunJobIcon } from "../../../assets/icons/runConfigurationIcon.svg";
import { ReactComponent as EditJobIcon } from "../../../assets/icons/editJobIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/deleteIcon2.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/downloadFileIcon.svg";
import { Stack, Switch, Typography } from "@mui/material";
import { toggleButtonStyles } from "./jobDetailsCardStyle";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { showSuccessAlert } from "utils/utils";
import { useDispatch } from "react-redux";
import "./jobDetailsCard.scss";
import api from "../../../apiInterceptor";
const run_jobAPITestData = require("../../../assets/apiTestData/run_job-test-data.json");
const setjobstateAPITestData = require("../../../assets/apiTestData/setjobstate-test-data.json");

const JobDetailsCard = ({
  jobDetails,
  getJobDetails,
  handleJobDeletion,
  editJob,
  showRunningJobs,
  downloadJobSummary,
}) => {
  const COMMON_API_URL = localStorage.getItem("COMMON_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const dispatch = useDispatch();
  const { updateIsLoading, updateAlertMessage, updateSuccessAlertMessage } =
    bindActionCreators(actionCreators, dispatch);

  const runJob = async (job) => {
    const apiUrl = COMMON_API_URL + "run_job";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = { job_id: job.job_id };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = run_jobAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const message = response.data.message;
        showSuccessAlert(message, updateSuccessAlertMessage);
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleJobStatusChange = async (event, job) => {
    const apiUrl = COMMON_API_URL + "setjobstate";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    let status = "INACTIVE";
    if (event.target.checked) {
      status = "ACTIVE";
    }
    const payload = {
      job_id: job.job_id,
      status: status,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = setjobstateAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const message = response.data.message;
        showSuccessAlert(message, updateSuccessAlertMessage);
        getJobDetails();
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  return (
    <div className="completed-card">
      <div className="job-operations-container button-bar icons-container">
        <Tooltip title="Delete job" placement="bottom">
          <div
            className={`icon-button  ${
              userAccessRestricted ? "disabled-button" : ""
            }`}
          >
            <IconButton
              onClick={() => handleJobDeletion(jobDetails)}
              disabled={userAccessRestricted}
              aria-label="Delete job"
              className="icon"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip
          title={
            jobDetails.status !== "ACTIVE"
              ? "Inactive job cannot be edited"
              : "Edit job"
          }
          placement="bottom"
        >
          <div
            className={`icon-button  ${
              jobDetails.status !== "ACTIVE" || userAccessRestricted
                ? "disabled-button"
                : ""
            }`}
          >
            <IconButton
              onClick={() => editJob(jobDetails)}
              disabled={jobDetails.status !== "ACTIVE" || userAccessRestricted}
              aria-label="Edit job"
              className="icon"
            >
              <EditJobIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip
          title={
            jobDetails.status !== "ACTIVE"
              ? "Cannot run inactive job"
              : "Run job"
          }
          placement="bottom"
        >
          <div
            className={`icon-button  ${
              jobDetails.status !== "ACTIVE" || userAccessRestricted
                ? "disabled-button"
                : ""
            }`}
          >
            <IconButton
              onClick={() => runJob(jobDetails)}
              disabled={jobDetails.status !== "ACTIVE" || userAccessRestricted}
              aria-label="Run job"
              className="icon"
            >
              <RunJobIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="View running jobs" placement="bottom">
          <div className="icon-button">
            <IconButton
              onClick={() => showRunningJobs(jobDetails)}
              aria-label="View running jobs"
              className=" icon"
            >
              <ViewRunningJobIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="Download job summary" placement="bottom">
          <div className="icon-button">
            <IconButton
              onClick={() => downloadJobSummary(jobDetails)}
              aria-label="Download job summary"
              className=" icon"
            >
              <DownloadIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Stack marginLeft={"5px"} direction="row" alignItems="center">
          <Typography>
            {jobDetails.status === "ACTIVE" ? "Active" : "Inactive"}
          </Typography>
          <Switch
            onChange={(e) => handleJobStatusChange(e, jobDetails)}
            aria-labelledby="job-status"
            name="jobStatus"
            sx={toggleButtonStyles}
            disabled={userAccessRestricted}
            checked={jobDetails.status === "ACTIVE"}
          />
        </Stack>
      </div>
      <div className="job-details">
        <div className="job-details-row">
          <div className="job-scheduler-col">
            <span className="job-head">Job ID</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.job_id}</span>
          </div>
          <div className="job-scheduler-col">
            <span className="job-head">Job TYPE</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.job_type}</span>
          </div>
        </div>
        <div className="job-details-row">
          <div className="job-scheduler-col">
            <span className="job-head">STARTED</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.start_date}</span>
          </div>

          <div className="job-scheduler-col">
            <span className="job-head">ENDED</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.end_date}</span>
          </div>
        </div>
        <div className="job-details-row">
          <div className="job-scheduler-col">
            <span className="job-head">PERIODICITY</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.ndays}</span>
          </div>

          <div className="job-scheduler-col">
            <span className="job-head">CONN TYPE</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.conn_type}</span>
          </div>
        </div>
        <div className="job-details-row">
          <div className="job-scheduler-col w-100">
            <span className="job-head">Job Description</span>
            <br />
            <span className="job-scheduler-body">{jobDetails.job_desc}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsCard;
